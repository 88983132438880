<template>
  <div class="impressum">
    <p class="impressum__paragraph">Impressum</p>
    <p>
      Jessica Schäfer<br />
      <a href="mailto:jessicaschaefer@mail.com"> jessicaschaefer@mail.com</a><br />
      Zeppelinstraße, 97074 Würzburg
    </p>

    <p>Verantwortlich für Web-Design & Web-Development: <a href="https://developerhub.tech/">developer:hub</a></p>

    <p class="impressum__paragraph">EU-Streitschlichtung:</p>
    <p>
      Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
      (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
      (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden
      an die Online Streitbeilegungsplattform der Europäischen Kommission unter
      <a
        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
        >http://ec.europa.eu/odr?tid=321264493</a
      >
      zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
      unserem Impressum.
    </p>
    <p>
      Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
      verpflichtet sind, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <p class="impressum__paragraph">Haftung für Inhalte dieser Website:</p>
    <p>
      Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns
      korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz
      <a href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321264493"
        >(TMG) §7 (1)</a
      >
      sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung
      bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können
      wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
      übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
      Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet,
      die von ihnen übermittelten oder gespeicherten Informationen zu überwachen
      oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.
    </p>
    <p>
      Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung
      der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von
      gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer
      Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
    </p>
    <p>
      Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte
      wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte
      entfernen können. Sie finden die Kontaktdaten im Impressum.
    </p>
    <p class="impressum__paragraph">Haftung für Links auf dieser Website:</p>
    <p>
      Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir
      nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns
      nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
      uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
      Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
    </p>
    <p>
      Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir
      Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
    </p>
    <p class="impressum__paragraph">Urheberrechtshinweis:</p>
    <p>
      Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen
      dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns
      bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder
      verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen.
      Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte
      unserer Seite rechtlich verfolgen.
    </p>
    <p>
      Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
      verletzen, bitten wir Sie uns zu kontaktieren.
    </p>
    <p class="impressum__paragraph">Bildernachweis:</p>
    <p>
      Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
      geschützt. <br />
      Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:<br />-
      Fotograf Mustermann
    </p>
    <p class="impressum__paragraph">Datenschutzerklärung - Datenschutz:</p>
    <p>
      Wir haben diese Datenschutzerklärung (Fassung 21.02.2021-321264493)
      verfasst, um Ihnen gemäß der Vorgaben der
      <a
        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321264493"
        >Datenschutz-Grundverordnung (EU) 2016/679</a
      >
      zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
      welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    </p>
    <p>
      Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese
      Version soll Ihnen hingegen die wichtigsten Dinge so einfach und klar wie
      möglich beschreiben. Soweit es möglich ist, werden technische Begriffe
      leserfreundlich erklärt. Außerdem möchten wir vermitteln, dass wir mit
      dieser Website nur dann Informationen sammeln und verwenden, wenn eine
      entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht
      möglich, wenn man möglichst knappe, technische Erklärungen abgibt, so wie
      sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe
      Sie finden die folgenden Erläuterungen interessant und informativ und
      vielleicht ist die eine oder andere Information dabei, die Sie noch nicht
      kannten. Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den
      vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten
      anzusehen, oder uns einfach eine E-Mail zu schreiben. Unsere Kontaktdaten
      finden Sie im Impressum.
    </p>
    <p>
      Quelle: Erstellt&nbsp;mit dem&nbsp;<a
        href="https://www.adsimple.de/datenschutz-generator/"
        >Datenschutz Generator</a
      >
      von AdSimple&nbsp;in Kooperation mit
      <a href="https://www.bauenwir.de">bauenwir.de</a>
    </p>
  </div>
</template>

<style scoped>
.impressum {
  padding-left: 5%;
  padding-right: 5%;
}
.impressum__paragraph {
  margin-top: 50px;
}
</style>
